<template>
	<v-container no-gutters fluid>
		<v-card flat style="padding-bottom: 50px;">
			<v-toolbar color="purple" dark extended flat>
				<v-toolbar-title>Controle</v-toolbar-title>
			</v-toolbar>

			<v-card class="mx-auto" max-width="90%" style="margin-top: -50px;">
				<v-toolbar flat class="grey lighten-3">
					<v-toolbar-title>Clientes</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Pesquisar" solo flat single-line hide-details style="max-width: 500px;"></v-text-field>
					<!--<v-btn icon><v-icon>mdi-filter</v-icon></v-btn>-->
				</v-toolbar>

				<v-divider></v-divider>

				<v-data-table :headers="gameHeaders" :items="userGames" :search="search">
					<template v-slot:item.dateFinished="{ item }">
						{{ item.dateFinished ? formatDate(item.dateFinished) : 'N/A' }}
					</template>
					<template v-slot:item.access="{ item }">
						<v-menu offset-y left>
							<template v-slot:activator="{ on }">
								<v-chip small label :color="item.accessColor" v-on="on" dark>{{ item.access }}</v-chip>
							</template>

							<v-list shaped rounded>
								<v-subheader class="grey--text font-weight-medium">{{ 'Nível de Acesso' }}</v-subheader>
								<template v-for="type in accessTypes">
									<v-list-item :key="type.id" @click="setAccessType(type, item)">
										<v-list-item-action>
											<v-icon :color="type.color">mdi-flag</v-icon>
										</v-list-item-action>
										<v-list-item-content>
											{{ type.name }}
										</v-list-item-content>
									</v-list-item>
								</template>
							</v-list>
						</v-menu>
					</template>
					<template v-slot:item.status="{ item }">
						<v-chip small label :color="item.statusColor" dark>{{ item.status }}</v-chip>
					</template>
					<template v-slot:item.acoes="{ item }">
						<v-tooltip top small v-if="item.isFinished">
							<template v-slot:activator="{ on, attrs }">
								<v-icon small color="primary" @click="viewReport(item)" v-bind="attrs" v-on="on">mdi-chart-box-outline</v-icon>
							</template>
							<span>Ver Relatório</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-card>
		</v-card>
	</v-container>
</template>

<script>
import moment from 'moment'

export default {
	name: 'AdminClients',
	data() {
		return {
			userData: [],
			userGames: [],

			// TABLE
			search: '',
			gameHeaders: [],

			// ACCESS
			accessTypes: [
				{ id: 1, name: 'Grátis', color: 'success' },
				{ id: 2, name: 'Simples', color: 'primary' },
				{ id: 3, name: 'Completo', color: 'primary lighten-2' },
				{ id: 4, name: 'Admin', color: 'error' }
			]
		}
	},
	mounted() {
		this.fetchData()
		this.mountTableHeaders()
	},
	methods: {
		fetchData() {
			this.userGames = []
			this.$store.dispatch('checkAuthAdmin').then(result => {
				if (result && result.id) {
					this.userData = result
					this.$store.dispatch('fetchAllUserGames', this.userData.id).then(res => {
						if (res && res.length > 0) {
							for (let game of res) {
								// SET ONLY IN PROGRESS OR FINISHED GAMES
								if (game.userData && game.userData.name && (game.isFinished || game.isInProgress)) {
									this.userGames.push(game)
								}
							}

							this.setTypeColors()
						}
					})
				}
			}).catch(() => {
				this.$router.push('/403')
			})
		},
		mountTableHeaders() {
			this.gameHeaders = [
				{ text: 'Nº Teste', value: 'id' },
				{ text: 'Nome', value: 'userData.name' },
				{ text: 'Email', value: 'userData.email' },
				{ text: 'Data do Teste', value: 'dateFinished' },
				{ text: 'Nível de Acesso', value: 'access' },
				{ text: 'Status', value: 'status' },
				{ text: 'Ações', value: 'acoes', align: 'center', sortable: false }
			]
		},
		viewReport(data) {
			this.$router.push(`/jogos/fac/relatorio?id=${data.id}&u=${data.code}`)
		},
		formatDate(date) {
			return moment(date).format('DD/MM/YYYY HH:mm')
		},
		setTypeColors() {
			if (this.userGames && this.userGames.length > 0) {
				for (let game of this.userGames) {
					// SET STATUS
					if (game.isExpired) {
						game.status = 'Vencido'
						game.statusColor = 'grey darken-3'
					}
					else if (game.isFinished && !game.isInProgress) {
						game.status = 'Finalizado'
						game.statusColor = 'success'
					}
					else if (!game.isFinished && game.isInProgress) {
						game.status = 'Em Progresso'
						game.statusColor = 'primary'
					}
					else if (!game.isFinished && !game.isInProgress) {
						game.status = 'Não Iniciado'
						game.statusColor = 'grey'
					}

					// SET ACCESS TYPES
					if (game.accessType === 1) {
						game.access = this.accessTypes[0].name
						game.accessColor = this.accessTypes[0].color
					}
					else if (game.accessType === 2) {
						game.access = this.accessTypes[1].name
						game.accessColor = this.accessTypes[1].color
					}
					else if (game.accessType === 3) {
						game.access = this.accessTypes[2].name
						game.accessColor = this.accessTypes[2].color
					}
					else if (game.accessType === 4) {
						game.access = this.accessTypes[3].name
						game.accessColor = this.accessTypes[3].color
					}
				}
			}
		},
		setAccessType(type, game) {
			if (this.userData && this.userData.id) {
				if (type.id !== game.accessType) {
					let updateData = {
						id: game.id,
						client: game.code,
						admin: this.userData.id,
						access: type.id
					}

					this.$store.dispatch('setUserGameAccessType', updateData).then(() => {
						this.fetchData()
						/*
						for (let user of this.userGames) {
							if (user.id === game.id && user.code === game.code) {
								user.accessType = type.id
								game.accessType = type.id
							}
						}

						this.$nextTick(() => {
							this.setTypeColors()
						})
						*/
					})
				}
			}
		}
	}
}
</script>
