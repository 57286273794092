var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"no-gutters":"","fluid":""}},[_c('v-card',{staticStyle:{"padding-bottom":"50px"},attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"color":"purple","dark":"","extended":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Controle")])],1),_c('v-card',{staticClass:"mx-auto",staticStyle:{"margin-top":"-50px"},attrs:{"max-width":"90%"}},[_c('v-toolbar',{staticClass:"grey lighten-3",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Clientes")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"500px"},attrs:{"prepend-inner-icon":"mdi-magnify","label":"Pesquisar","solo":"","flat":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.gameHeaders,"items":_vm.userGames,"search":_vm.search},scopedSlots:_vm._u([{key:"item.dateFinished",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dateFinished ? _vm.formatDate(item.dateFinished) : 'N/A')+" ")]}},{key:"item.access",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"small":"","label":"","color":item.accessColor,"dark":""}},on),[_vm._v(_vm._s(item.access))])]}}],null,true)},[_c('v-list',{attrs:{"shaped":"","rounded":""}},[_c('v-subheader',{staticClass:"grey--text font-weight-medium"},[_vm._v(_vm._s('Nível de Acesso'))]),_vm._l((_vm.accessTypes),function(type){return [_c('v-list-item',{key:type.id,on:{"click":function($event){return _vm.setAccessType(type, item)}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":type.color}},[_vm._v("mdi-flag")])],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(type.name)+" ")])],1)]})],2)],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":item.statusColor,"dark":""}},[_vm._v(_vm._s(item.status))])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [(item.isFinished)?_c('v-tooltip',{attrs:{"top":"","small":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.viewReport(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-chart-box-outline")])]}}],null,true)},[_c('span',[_vm._v("Ver Relatório")])]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }